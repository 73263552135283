import constants from './constants';
import defaultInitialState from '../defaultInitialState';
import { strings } from '../../utils/index';

const initialState = {
  missionsList: [],
  notification: null,
  missionsCount: 0,
  activeMission: {},
  ...defaultInitialState,
  isCreatePending: false,
  isCreateError: false,
  sortField: 'createdAt',
  sortOrder: 'desc',
};

const errorMessageHandling = payload => payload.errors[0].title || strings.GENERAL_ERROR;

const missions = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    //GET_NOTIFICATIONS
    case constants.GET_MISSIONS_FULFILLED:
      return {
        ...state,
        missionsList: payload.data,
        missionCount: payload.meta.totalCount,
        isPending: false,
      };
    case constants.GET_MISSIONS_PENDING:
      return {
        ...state,
        isPending: true,
        isError: false,
        isCreateError: false,
        errorMessage: '',
        notification: null,
      };
    case constants.GET_MISSIONS_REJECTED:
      return {
        ...state,
        isPending: false,
        isError: true,
        errorMessage: errorMessageHandling(payload),
      };

    case constants.GET_MISSION_BY_ID_FULFILLED:
      return {
        ...state,
        mission: payload.data,
        isPending: false,
      };
    case constants.GET_MISSION_BY_ID_PENDING:
      return {
        ...state,
        isPending: true,
        isError: false,
        errorMessage: '',
      };
    case constants.GET_MISSION_BY_ID_REJECTED:
      return {
        ...state,
        isPending: false,
        isError: true,
        errorMessage: errorMessageHandling(payload),
      };

    case constants.CREATE_MISSION_FULFILLED:
      return {
        ...state,
        isCreatePending: false,
        mission: payload.data,
      };
    case constants.CREATE_MISSION_PENDING:
      return {
        ...state,
        isCreatePending: true,
        isCreateError: false,
        errorMessage: '',
      };
    case constants.CREATE_MISSION_REJECTED:
      return {
        ...state,
        isCreatePending: false,
        isCreateError: true,
        errorMessage: errorMessageHandling(payload),
      };

    case constants.UPDATE_MISSION_FULFILLED:
      return {
        ...state,
        mission: payload.data,
        isMissionUpdated: true,
        isPending: false,
        isUpdatePending: false,
      };
    case constants.UPDATE_MISSION_PENDING:
      return {
        ...state,
        isUpdatePending: true,
        isError: false,
        isMissionUpdated: false,
        errorMessage: '',
      };
    case constants.UPDATE_MISSION_REJECTED:
      return {
        ...state,
        isPending: false,
        isError: true,
        errorMessage: errorMessageHandling(payload),
        isUpdatePending: false,
      };

    case constants.GET_ACTIVE_MISSION_FULFILLED:
      return {
        ...state,
        activeMission: payload.data,
      };

    case constants.GET_ACTIVE_MISSION_REJECTED:
      return {
        ...state,
        isError: true,
        errorMessage: errorMessageHandling(payload),
      };

    case constants.SET_MISSIONS_SORT:
      const { sortField, sortOrder } = payload;
      return {
        ...state,
        sortField,
        sortOrder,
      };

    default:
      return state;
  }
};

export default missions;
