import React from "react";
import PropTypes from "prop-types";
import { Modal, Checkbox, BasicInput, Dropdown, DateTime } from "../../index";
import { strings, validate } from "../../../utils";
import { identifiers } from "../../../constants";
import "./createModal.scss";

import plus from "../../../assets/images/plus.svg";
import timezones from "../../../constants/timezones.json";

const INPUTS = [
  { id: identifiers.TITLE, label: strings.TITLE, type: "text" },
  { id: identifiers.MESSAGE, label: strings.MESSAGE, type: "textarea" },
  {
    id: identifiers.LONG_MESSAGE,
    label: strings.LONG_MESSAGE,
    type: "textarea",
  },
  {
    id: identifiers.IS_SCHEDULED,
    label: strings.IS_SCHEDULED,
    type: "boolean",
    value: false,
  },
  {
    id: identifiers.SCHEDULED_DATE,
    label: strings.SCHEDULED_DATE,
    type: "date-time",
  },
  {
    id: identifiers.SCHEDULED_TIMEZONE,
    label: strings.SCHEDULED_TIMEZONE,
    type: "select",
    options: timezones,
    value: "Romance Standard Time",
  }, // defaults to Brussels
  {
    id: identifiers.SAVE_AS_DRAFT,
    label: strings.SAVE_AS_DRAFT,
    type: "boolean",
    value: false,
  },
];

class CreateModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputs: this.initializeCreateParameters(),
    };
  }

  initializeCreateParameters = () => {
    let inputs = {};
    INPUTS.forEach((item) => {
      if (item.type === "email") {
        inputs[item.id] = {
          value: "",
          validation: "email",
          isValid: true,
          errorMessage: "",
        };
      } else if (item.id === identifiers.LONG_MESSAGE) {
        inputs[item.id] = {
          value: "",
          validation: false,
          isValid: true,
          errorMessage: "",
        };
      } else if (
        item.type === "text" ||
        item.type === "textarea" ||
        item.type === "number" ||
        item.type === "date"
      ) {
        inputs[item.id] = {
          value: "",
          validation: "text",
          isValid: true,
          errorMessage: "",
        };
      } else if (item.type === "date-time") {
        inputs[item.id] = {
          value: { date: "", time: "" },
          validation: this.customValidation,
          isValid: true,
          errorMessage: "",
        };
      } else if (item.type === "select" && item.options.length > 0) {
        inputs[item.id] = {
          value: item.value || item.options[0].key,
          validation: null,
        };
      } else if (item.type === "boolean") {
        inputs[item.id] = { value: item.value, validation: null };
      }
    });
    return inputs;
  };

  setCreateParameters = () => {
    this.setState({ inputs: this.initializeCreateParameters() });
  };

  customValidation = (value) => {
    if (!this.state.inputs[identifiers.IS_SCHEDULED].value)
      return { isValid: true };
    return validate("date-time", value);
  };

  validate = () => {
    let inputs = { ...this.state.inputs };
    let hasError = false;
    Object.keys(inputs)
      .filter((key) => inputs.hasOwnProperty(key) && inputs[key].validation)
      .forEach((key) => {
        const validationResult =
          typeof inputs[key].validation === "function"
            ? inputs[key].validation(inputs[key].value)
            : validate(inputs[key].validation, inputs[key].value);
        if (!validationResult.isValid) {
          hasError = true;
        }
        inputs[key] = {
          ...inputs[key],
          ...validationResult,
        };
      });
    if (hasError) {
      this.setState({ inputs });
      return;
    }
    return this.create(this.getInputValues(inputs));
  };

  getInputValues(inputs) {
    for (let key in inputs) {
      inputs[key] = inputs[key].value;
    }
    return inputs;
  }

  create = (inputs) => {
    return this.props.create(inputs);
  };

  handleChange = (event) => {
    const input = event.target.id.replace("modal-", "");
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: {
          ...this.state.inputs[input],
          value:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          isValid: true,
        },
      },
    });
  };

  handleDateChange = ({ date, time, id }) => {
    const input = id.replace("modal-", "");
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: {
          ...this.state.inputs[input],
          value: { date, time },
          isValid: true,
        },
      },
    });
  };

  renderInput = (item) => {
    if (item.type === "boolean") {
      return (
        <Checkbox
          key={item.id}
          id={`modal-${item.id}`}
          text={item.label}
          value={this.state.inputs[item.id].value}
          handleChange={this.handleChange}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (
      item.type === "select" &&
      item.options &&
      this.state.inputs[item.id]
    ) {
      return (
        <Dropdown
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          handleChange={this.handleChange}
          options={item.options}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (item.type === "date-time") {
      return (
        <DateTime
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          isValid={this.state.inputs[item.id].isValid}
          errorMessage={this.state.inputs[item.id].errorMessage}
          handleChange={this.handleDateChange}
          type={item.type}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (this.state.inputs[item.id]) {
      return (
        <BasicInput
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          isValid={this.state.inputs[item.id].isValid}
          errorMessage={this.state.inputs[item.id].errorMessage}
          handleChange={this.handleChange}
          type={item.type}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    }
  };

  render() {
    return (
      <Modal
        id={strings.CREATE}
        modalButtonText={this.props.primaryButtonText}
        secondaryButtonText={strings.CANCEL}
        handlePrimaryButton={this.validate}
        primaryButtonText={strings.SUBMIT}
        primaryButtonClassName="btn-success"
        modalButtonClassName="btn-success btn-create"
        icon={plus}
        title={this.props.title}
        hasHeader
        isPending={this.props.isPending}
        handleModalButton={this.setCreateParameters}
      >
        <div className="create-modal-input">
          {this.renderInput(INPUTS[0]) /* TITLE */}
          {this.renderInput(INPUTS[1]) /* MESSAGE */}
          {this.renderInput(INPUTS[2]) /* LONG_MESSAGE */}
          {this.renderInput(INPUTS[3]) /* IS_SCHEDULED */}
          {
            this.renderInput({
              ...INPUTS[4],
              isDisabled: !this.state.inputs[identifiers.IS_SCHEDULED].value,
            }) /* SCHEDULED_DATE */
          }
          {
            this.renderInput({
              ...INPUTS[5],
              isDisabled: !this.state.inputs[identifiers.IS_SCHEDULED].value,
            }) /* SCHEDULED_TIMEZONE */
          }
          {this.renderInput(INPUTS[6]) /* SAVE_AS_DRAFT */}
        </div>
      </Modal>
    );
  }
}

CreateModal.propTypes = {
  primaryButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  create: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

CreateModal.defaultProps = {
  isPending: false,
  isError: false,
  errorMessage: "",
};

export default CreateModal;
