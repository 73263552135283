/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_USERS: 'GET_USERS',
  GET_USERS_PENDING: 'GET_USERS_PENDING',
  GET_USERS_REJECTED: 'GET_USERS_REJECTED',
  GET_USERS_FULFILLED: 'GET_USERS_FULFILLED',

  GET_USERS_BY_ID: 'GET_USERS_BY_ID',
  GET_USERS_BY_ID_PENDING: 'GET_USERS_BY_ID_PENDING',
  GET_USERS_BY_ID_REJECTED: 'GET_USERS_BY_ID_REJECTED',
  GET_USERS_BY_ID_FULFILLED: 'GET_USERS_BY_ID_FULFILLED',

  CREATE_USER: 'CREATE_USER',
  CREATE_USER_PENDING: 'CREATE_USER_PENDING',
  CREATE_USER_REJECTED: 'CREATE_USER_REJECTED',
  CREATE_USER_FULFILLED: 'CREATE_USER_FULFILLED',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
  UPDATE_USER_REJECTED: 'UPDATE_USER_REJECTED',
  UPDATE_USER_FULFILLED: 'UPDATE_USER_FULFILLED',

  REMOVE_USER: 'REMOVE_USER',
  REMOVE_USER_PENDING: 'REMOVE_USER_PENDING',
  REMOVE_USER_REJECTED: 'REMOVE_USER_REJECTED',
  REMOVE_USER_FULFILLED: 'REMOVE_USER_FULFILLED',

  GET_USER_ROLES: 'GET_USER_ROLES',
  GET_USER_ROLES_PENDING: 'GET_USER_ROLES_PENDING',
  GET_USER_ROLES_REJECTED: 'GET_USER_ROLES_REJECTED',
  GET_USER_ROLES_FULFILLED: 'GET_USER_ROLES_FULFILLED',

  SET_USERS_SORT: 'SET_USERS_SORT',
};
