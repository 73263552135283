export const identifiers = {
  ACTIVE: "active",
  CODE: "code",
  CREATED_AT: "createdAt",
  DEPRECATED: "deprecated",
  DESCRIPTION: "description",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  STATUS_NAME: "status.name",
  STATUS: "status",
  ID: "id",
  LAST_NAME: "lastName",
  MESSAGE_DANGER: "danger",
  MESSAGE_SUCCESS: "success",
  MESSAGE_WARNING: "warning",
  NAME: "name",
  PASSWORD: "password",
  REGISTRATION_COMPLETED: "registrationCompleted",
  REPEAT_PASSWORD: "repeatPassword",
  ROLE_NAME: "role.name",
  ROLE: "role",
  TYPE: "type",
  UNDEPRECATED: "undeprecated",
  UPDATED_AT: "updatedAt",
  USER_HAS_TO_SET_PASSWORD: "userHasToSetPassword",
  VALUE: "value",
  TITLE: "title",
  BODY: "body",
  MESSAGE: "message",
  LONG_MESSAGE: "longMessage",
  IS_SCHEDULED: "isScheduled",
  SCHEDULED_DATE: "scheduledDate",
  SCHEDULED_TIMEZONE: "scheduledTimeZone",
  SAVE_AS_DRAFT: "saveAsDraft",
  START_DATE: "startDate",
  END_DATE: "endDate",
  CURRENCY_FROM: "currencyFrom",
  CURRENCY_TO: "currencyTo",
  CURRENCY_FALLBACK_RATE: "currencyFallbackRate",
  RATING: "showAppReviewPrompt",
  IS_ACTIVE_MISSION: "isActiveMission",
  BELFIUS_LINK: "belfiusLink",
  STATUS_CODE: "statusCode",
  STATUS_DRAFT: "draft",
};
