import { Network } from "../utils";
export const create = async (missionId, notification) => {
  return await Network.post(
    `/missions/${missionId}/notifications`,
    notification
  );
};
export const update = async (id, notification) =>
  Network.put(`/notifications/${id}`, notification);
export const sendNotification = async (id) =>
  Network.put(`/notifications/${id}/send`);
export const removeNotification = async (id) =>
  Network.delete(`/notifications/${id}`);

export const get = async (page, limit, sortField, sortOrder, search = "") => {
  const pagination = `?offset=${page}&limit=${limit}`;
  const sort =
    sortField && sortField !== ""
      ? `&sortField=${sortField}&sortOrder=${sortOrder}`
      : "";
  const filter = search !== "" ? `&search=${search}` : "";
  const result = await Network.get(
    `/notifications${pagination}${sort}${filter}`
  );
  return result;
};
