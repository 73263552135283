import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import UserOverview from '../scenes/users/UserOverview';
import { Header, Navbar } from '../components';
import { strings } from '../utils';
import UserDetail from '../scenes/users/UserDetail';
import LanguageCodesOverview from '../scenes/languageCodes/LanguageCodesOverview';
import LanguageCodesDetail from '../scenes/languageCodes/LanguageCodesDetail';
import NotificationsOverview from '../scenes/pushNotifications/NotificationsOverview';
import NotificationDetail from '../scenes/pushNotifications/NotificationDetail';
import MissionOverview from '../scenes/missions/MissionOverview';
import MissionDetail from '../scenes/missions/MissionDetail';

const AuthorizedLayout = props => {
  return (
    <React.Fragment>
      <Header toggleNavigation={props.toggleNavigation} logout={props.logoutUser} />
      <div className="container-fluid">
        <div className="row">
          <Navbar
            links={[
              { name: strings.USERS, path: '/users' },
              { name: strings.LANGUAGE_CODES, path: '/language-codes' },
              { name: strings.NOTIFICATIONS, path: '/notifications' },
              { name: strings.MISSIONS, path: '/missions' },
            ]}
            isNavigationShown={props.isNavigationShown}
            toggleNavigation={props.toggleNavigation}
            build={props.build}
            version={props.version}
          />
          <Switch>
            <Route path='/users' exact component={UserOverview} />
            <Route path='/users/:id' exact component={UserDetail} />
            <Route path='/language-codes' exact component={LanguageCodesOverview} />
            <Route path='/language-codes/:id' exact component={LanguageCodesDetail} />
            <Route path='/notifications' exact component={NotificationsOverview} />
            <Route path='/notifications/:id' exact component={NotificationDetail} />
            <Route path='/missions/' exact component={MissionOverview} />
            <Route path='/missions/:id' exact component={MissionDetail} />
            <Redirect to='/users' />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthorizedLayout;
