import constants from './constants';
import { missionService } from '../../services';

export const getMissions = (page, limit, sortField, sortOrder, search) => {
  return {
    type: constants.GET_MISSIONS,
    payload: missionService.get(page, limit, sortField, sortOrder, search),
  };
};

export const getMissionById = id => {
  return {
    type: constants.GET_MISSION_BY_ID,
    payload: missionService.getMissionById(id),
  };
};

export const setSort = (sortField, sortOrder) => {
  return {
    type: constants.SET_MISSIONS_SORT,
    payload: { sortField, sortOrder },
  };
};

export const createMission = (mission) => {
  return {
    type: constants.CREATE_MISSION,
    payload: missionService.create(mission),
  };
};

export const getActiveMission = () => {
  return {
    type: constants.GET_ACTIVE_MISSION,
    payload: missionService.getActiveMission(),
  };
};


export const updateMission = (id, user) => {
  return {
    type: constants.UPDATE_MISSION,
    payload: missionService.update(id, user),
  };
};

export const createMissionImage = (id, file, type, timeOfDay) => {
  return {
    type: constants.CREATE_MISSION_IMAGE,
    payload: missionService.createImage(id, file, type, timeOfDay),
  };
};


