/* eslint-disable import/no-anonymous-default-export */
export default {
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_PENDING: 'LOGIN_USER_PENDING',
  LOGIN_USER_REJECTED: 'LOGIN_USER_REJECTED',
  LOGIN_USER_FULFILLED: 'LOGIN_USER_FULFILLED',

  GET_ME: 'GET_ME',
  GET_ME_PENDING: 'GET_ME_PENDING',
  GET_ME_REJECTED: 'GET_ME_REJECTED',
  GET_ME_FULFILLED: 'GET_ME_FULFILLED',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_PENDING: 'FORGOT_PASSWORD_PENDING',
  FORGOT_PASSWORD_REJECTED: 'FORGOT_PASSWORD_REJECTED',
  FORGOT_PASSWORD_FULFILLED: 'FORGOT_PASSWORD_FULFILLED',

  VERIFY_FORGOT_PASSWORD: 'VERIFY_FORGOT_PASSWORD',
  VERIFY_FORGOT_PASSWORD_PENDING: 'VERIFY_FORGOT_PASSWORD_PENDING',
  VERIFY_FORGOT_PASSWORD_REJECTED: 'VERIFY_FORGOT_PASSWORD_REJECTED',
  VERIFY_FORGOT_PASSWORD_FULFILLED: 'VERIFY_FORGOT_PASSWORD_FULFILLED',

  CONFIRM_FORGOT_PASSWORD: 'CONFIRM_FORGOT_PASSWORD',
  CONFIRM_FORGOT_PASSWORD_PENDING: 'CONFIRM_FORGOT_PASSWORD_PENDING',
  CONFIRM_FORGOT_PASSWORD_REJECTED: 'CONFIRM_FORGOT_PASSWORD_REJECTED',
  CONFIRM_FORGOT_PASSWORD_FULFILLED: 'CONFIRM_FORGOT_PASSWORD_FULFILLED',

  LOGOUT_USER: 'LOGOUT_USER',
  LOGOUT_USER_PENDING: 'LOGOUT_USER_PENDING',
  LOGOUT_USER_REJECTED: 'LOGOUT_USER_REJECTED',
  LOGOUT_USER_FULFILLED: 'LOGOUT_USER_FULFILLED',

  LOGOUT_USER_FORCE: 'LOGOUT_USER_FORCE',
};
