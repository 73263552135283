import constants from "./constants";
import defaultInitialState from "../defaultInitialState";
import { strings } from "../../utils/index";

const initialState = {
  notificationsList: [],
  notification: null,
  notificationsCount: 0,
  ...defaultInitialState,
  isCreatePending: false,
  isCreateError: false,
  sortField: "createdAt",
  sortOrder: "desc",
  editNotification: null,
};

const errorMessageHandling = (payload) =>
  payload.errors[0].title || strings.GENERAL_ERROR;

const notifications = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    //GET_NOTIFICATIONS
    case constants.GET_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        notificationsList: payload.data.map((notification) => ({
          ...notification,
          statusCode: notification.status.code,
        })),
        notificationsCount: payload.meta.totalCount,
        isPending: false,
      };
    case constants.GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        isPending: true,
        isError: false,
        isCreateError: false,
        errorMessage: "",
        notification: null,
      };

    case constants.GET_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        isPending: false,
        isError: true,
        errorMessage: errorMessageHandling(payload),
      };

    // CREATE NOTIFICATION
    case constants.CREATE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        notificationsList: [
          { ...payload.data, statusCode: payload.data.status.code },
          ...state.notificationsList,
        ],
        isCreateError: false,
        isCreatePending: false,
        isPending: false,
      };

    case constants.CREATE_NOTIFICATION_PENDING:
      return {
        ...state,
        isCreateError: false,
        isCreatePending: true,
        errorMessage: "",
      };

    case constants.CREATE_NOTIFICATION_REJECTED:
      return {
        ...state,
        isCreateError: true,
        isCreatePending: false,
        errorMessage: errorMessageHandling(payload),
      };

    // UPDATE NOTIFICATION
    case constants.UPDATE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        isCreateError: false,
        isCreatePending: false,
        isPending: false,
      };

    case constants.UPDATE_NOTIFICATION_PENDING:
      return {
        ...state,
        isCreateError: false,
        isCreatePending: true,
        errorMessage: "",
      };

    case constants.UPDATE_NOTIFICATION_REJECTED:
      return {
        ...state,
        isCreateError: true,
        isCreatePending: false,
        errorMessage: errorMessageHandling(payload),
      };

    // SEND NOTIFICATION
    case constants.SEND_NOTIFICATION_FULFILLED:
      return {
        ...state,
      };

    case constants.SEND_NOTIFICATION_PENDING:
      return {
        ...state,
        errorMessage: "",
      };

    case constants.SEND_NOTIFICATION_REJECTED:
      return {
        ...state,
        errorMessage: errorMessageHandling(payload),
      };

    case constants.SET_NOTIFICATIONS_SORT:
      const { sortField, sortOrder } = payload;
      return {
        ...state,
        sortField,
        sortOrder,
      };

    case constants.SET_EDIT_NOTIFICATION:
      return {
        ...state,
        editNotification: payload,
      };

    default:
      return state;
  }
};

export default notifications;
