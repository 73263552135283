import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Overview } from "../../components";
import { strings } from "../../utils";
import { identifiers, currencies } from "../../constants";
import {
  getMissions,
  setSort,
  createMission,
} from "../../redux/missions/actions";
import constants from "../../redux/missions/constants";
import { setMessage } from "../../redux/messages/actions";

class MissionOverview extends Component {
  getMissionsSorted = (page, limit, sortField, sortOrder, search = "") => {
    if (sortField && sortOrder) {
      this.props.setSort(sortField, sortOrder);
    }
    this.props.getMissions(
      page,
      limit,
      sortField || this.props.sortField,
      sortOrder || this.props.sortOrder,
      search
    );
  };

  createMission = async (mission) => {
    mission.isActiveMission = false;

    if (!mission.showAppReviewPrompt) {
      mission.showAppReviewPrompt = false;
    }

    const result = await this.props.createMission(mission);

    if (
      result.action &&
      result.action.type === constants.CREATE_MISSION_FULFILLED
    ) {
      this.props.setMessage({
        type: identifiers.MESSAGE_SUCCESS,
        text: strings.NEW_MISSION_CREATED,
      });
      return true;
    }
    return false;
  };

  render() {
    return (
      <Overview
        title={strings.MISSIONS}
        keyword={strings.MISSIONS}
        keys={[
          {
            id: identifiers.NAME,
            value: strings.NAME,
            isSortable: true,
            sorter: identifiers.NAME,
            width: 4,
          },
          {
            id: identifiers.START_DATE,
            value: strings.START_DATE,
            isSortable: true,
            sorter: identifiers.START_DATE,
            width: 4,
          },
          {
            id: identifiers.END_DATE,
            value: strings.END_DATE,
            isSortable: true,
            sorter: identifiers.END_DATE,
            width: 4,
          },
          {
            id: identifiers.IS_ACTIVE_MISSION,
            value: strings.IS_ACTIVE_MISSION,
            isSortable: true,
            sorter: identifiers.IS_ACTIVE_MISSION,
            width: 1,
          },
        ]}
        create={this.createMission}
        listItems={this.props.missions}
        history={this.props.history}
        paginationTotalCount={this.props.missionCount}
        get={this.getMissionsSorted}
        createParameters={[
          { id: identifiers.NAME, label: strings.NAME, type: "text" },
          {
            id: identifiers.START_DATE,
            label: strings.START_DATE,
            type: "date",
          },
          { id: identifiers.END_DATE, label: strings.END_DATE, type: "date" },
          {
            id: identifiers.CURRENCY_FROM,
            label: strings.CURRENCY_FROM,
            type: "select",
            options: currencies,
            value: "EUR",
          },
          {
            id: identifiers.CURRENCY_TO,
            label: strings.CURRENCY_TO,
            type: "select",
            options: currencies,
            value: "EUR",
          },
          {
            id: identifiers.CURRENCY_FALLBACK_RATE,
            tooltip: strings.CURRENCY_FALLBACK_RATE_TOOLTIP,
            label: strings.CURRENCY_FALLBACK_RATE,
            type: "number",
          },
          { id: identifiers.RATING, label: strings.RATING, type: "boolean" },
        ]}
        isError={this.props.isError}
        errorMessage={this.props.errorMessage}
        isCreatePending={this.props.isCreatePending}
        isCreateError={this.props.isCreateError}
        deleteIdentifier={identifiers.NAME}
        sortField={this.props.sortField}
        sortOrder={this.props.sortOrder}
        setMessage={this.props.setMessage}
      />
    );
  }
}

MissionOverview.propTypes = {
  missions: PropTypes.array.isRequired,
  missionCount: PropTypes.number.isRequired,
  createMission: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getMissions: PropTypes.func.isRequired,
  isCreatePending: PropTypes.bool.isRequired,
  isCreateError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  missions: state.missions.missionsList,
  missionCount: state.missions.missionCount,
  isError: state.missions.isError,
  errorMessage: state.missions.errorMessage,
  isCreatePending: state.missions.isCreatePending,
  isCreateError: state.missions.isCreateError,
  sortField: state.missions.sortField,
  sortOrder: state.missions.sortOrder,
});

const mapDispatchToProps = {
  getMissions,
  createMission,
  setSort,
  setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionOverview);
