import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import {
  updateNotification,
  sendNotification,
} from "../../redux/pushNotifications/actions";
import {
  BasicInput,
  Button,
  Checkbox,
  Dropdown,
  EmptyDetail,
  DateTime,
} from "../../components";
import { strings, validate } from "../../utils";
import { identifiers } from "../../constants";
import constants from "../../redux/pushNotifications/constants";
import { setMessage } from "../../redux/messages/actions";

import arrowLeft from "../../assets/images/arrow-left.svg";
import timezones from "../../constants/timezones.json";
const NOTIFICATION_STATUS = {
  draft: "DRAFT",
  scheduled: "SCHEDULED",
  ready: "READY",
};

class NotificationDetail extends Component {
  constructor(props) {
    super(props);
    if (props.notification) {
      const INPUTS = [
        {
          id: identifiers.TITLE,
          label: strings.TITLE,
          type: "text",
          value: this.props.notification.title,
        },
        {
          id: identifiers.MESSAGE,
          label: strings.MESSAGE,
          type: "textarea",
          value: this.props.notification.messageBody,
        },
        {
          id: identifiers.LONG_MESSAGE,
          label: strings.LONG_MESSAGE,
          type: "textarea",
          value: this.props.notification.body,
        },
        {
          id: identifiers.IS_SCHEDULED,
          label: strings.IS_SCHEDULED,
          type: "boolean",
          value: !!this.props.notification.scheduled,
        },
        {
          id: identifiers.SCHEDULED_DATE,
          label: strings.SCHEDULED_DATE,
          type: "date-time",
          value: this.props.notification.scheduled
            ? {
                date: this.props.notification.scheduled.split("T")[0],
                time: this.props.notification.scheduled
                  .split("T")[1]
                  .split(".")[0],
              }
            : {},
        },
        {
          id: identifiers.SCHEDULED_TIMEZONE,
          label: strings.SCHEDULED_TIMEZONE,
          type: "select",
          options: timezones,
          value:
            this.timezoneValue(this.props.notification.timezone) ||
            "Europe/Brussels",
        },
      ];

      this.state = {
        inputItems: INPUTS,
        inputs: this.initializeCreateParameters(INPUTS),
      };
    }
  }

  timezoneValue = (value) => {
    const findTimezone = timezones.find((t) =>
      t.utc ? t.utc.includes(value) : false
    );
    return findTimezone ? findTimezone.key : undefined;
  };

  createTimezonedTime = (dateTime, timezone) => {
    // Convert the date and timezone to the following format '2007-04-05T12:30-02:00';
    const { date, time } = dateTime || {};
    if (!date || !time || !timezone) return undefined;
    const tz = timezones.find((tz) => tz.key === timezone);
    const timezoned = moment.tz(`${date} ${time}`, tz.utc[0]);

    return timezoned.format();
  };

  getTimeZone = (notification) => {
    return timezones.find(
      (timezone) => timezone.key === notification.scheduledTimeZone
    ).utc[0];
  };

  initializeCreateParameters = (INPUTS) => {
    let inputs = {};
    INPUTS.forEach((item) => {
      if (item.type === "email") {
        inputs[item.id] = {
          value: item.value || "",
          validation: "email",
          isValid: true,
          errorMessage: "",
        };
      } else if (item.id === identifiers.LONG_MESSAGE) {
        inputs[item.id] = {
          value: item.value || "",
          validation: false,
          isValid: true,
          errorMessage: "",
        };
      } else if (
        item.type === "text" ||
        item.type === "textarea" ||
        item.type === "number" ||
        item.type === "date"
      ) {
        inputs[item.id] = {
          value: item.value || "",
          validation: "text",
          isValid: true,
          errorMessage: "",
        };
      } else if (item.type === "date-time") {
        inputs[item.id] = {
          value: { date: item.value.date || "", time: item.value.time || "" },
          validation: this.customValidation,
          isValid: true,
          errorMessage: "",
        };
      } else if (item.type === "select" && item.options.length > 0) {
        inputs[item.id] = {
          value: item.value || item.options[0].key,
          validation: null,
        };
      } else if (item.type === "boolean") {
        inputs[item.id] = { value: item.value, validation: null };
      }
    });
    return inputs;
  };

  renderInput = (item) => {
    if (item.type === "boolean") {
      return (
        <Checkbox
          key={item.id}
          id={`modal-${item.id}`}
          text={item.label}
          value={this.state.inputs[item.id].value}
          handleChange={this.handleChange}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (
      item.type === "select" &&
      item.options &&
      this.state.inputs[item.id]
    ) {
      return (
        <Dropdown
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          handleChange={this.handleChange}
          options={item.options}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (item.type === "date-time") {
      return (
        <DateTime
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          isValid={this.state.inputs[item.id].isValid}
          errorMessage={this.state.inputs[item.id].errorMessage}
          handleChange={this.handleDateChange}
          type={item.type}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    } else if (this.state.inputs[item.id]) {
      return (
        <BasicInput
          key={item.id}
          id={`modal-${item.id}`}
          label={item.label}
          value={this.state.inputs[item.id].value}
          isValid={this.state.inputs[item.id].isValid}
          errorMessage={this.state.inputs[item.id].errorMessage}
          handleChange={this.handleChange}
          type={item.type}
          isDisabled={this.props.isPending || item.isDisabled}
        />
      );
    }
  };

  handleChange = (event) => {
    const input = event.target.id.replace("modal-", "");
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: {
          ...this.state.inputs[input],
          value:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          isValid: true,
        },
      },
    });
  };

  handleDateChange = ({ date, time, id }) => {
    const input = id.replace("modal-", "");
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: {
          ...this.state.inputs[input],
          value: { date, time },
          isValid: true,
        },
      },
    });
  };

  validate = (send) => {
    let inputs = { ...this.state.inputs };
    let hasError = false;
    Object.keys(inputs)
      .filter((key) => inputs.hasOwnProperty(key) && inputs[key].validation)
      .forEach((key) => {
        const validationResult =
          typeof inputs[key].validation === "function"
            ? inputs[key].validation(inputs[key].value)
            : validate(inputs[key].validation, inputs[key].value);
        if (!validationResult.isValid) {
          hasError = true;
        }
        inputs[key] = {
          ...inputs[key],
          ...validationResult,
        };
      });
    if (hasError) {
      this.setState({ inputs });
      return;
    }

    return this.update(this.getInputValues(inputs), send);
  };

  getInputValues(inputs) {
    for (let key in inputs) {
      inputs[key] = inputs[key].value;
    }
    return inputs;
  }

  update = async (notification, send) => {
    let status = NOTIFICATION_STATUS.draft;

    if (notification.isScheduled && send) {
      status = NOTIFICATION_STATUS.scheduled;
    }

    const formattedNotification = {
      title: notification.title,
      messageBody: notification.message,
      body: notification.longMessage,
      status,
      scheduled: notification.isScheduled
        ? this.createTimezonedTime(
            notification.scheduledDate,
            notification.scheduledTimeZone
          )
        : undefined,
      timezone: this.getTimeZone(notification),
    };

    const result = await this.props.updateNotification(
      this.props.notification.id,
      formattedNotification
    );
    if (send && !formattedNotification.scheduled) {
      const result = await this.props.sendNotification(
        this.props.notification.id
      );

      if (
        result.action &&
        result.action.type === constants.SEND_NOTIFICATION_FULFILLED
      ) {
        this.props.history.goBack();
        return true;
      }
    }
    if (
      result.action &&
      result.action.type === constants.UPDATE_NOTIFICATION_FULFILLED
    ) {
      this.props.setMessage({
        type: identifiers.MESSAGE_SUCCESS,
        text: strings.NOTIFICATION_UPDATED,
      });
      return true;
    }
    return false;
  };

  render() {
    const { props } = this;
    const overview = window.location.pathname.split("/")[1];
    if (props.notification) {
      return (
        <main className="detail col-sm-9 offset-sm-3 col-md-10 offset-md-2 pt-3">
          <div className="container">
            <div className="detail-header">
              <div className="back text-primary" onClick={props.history.goBack}>
                <img src={arrowLeft} alt={strings.PREVIOUS} />
                <span>{`${overview.charAt(0).toUpperCase()}${overview.slice(
                  1
                )}`}</span>
              </div>
            </div>
            <h3>{props.notification.title}</h3>
            <span className="text-primary">{`${strings.ID}: ${props.notification.id}`}</span>
            {this.state.inputItems && (
              <div className="create-modal-input">
                {this.renderInput(this.state.inputItems[0]) /* TITLE */}
                {this.renderInput(this.state.inputItems[1]) /* MESSAGE */}
                {this.renderInput(this.state.inputItems[2]) /* LONG_MESSAGE */}
                {this.renderInput(this.state.inputItems[3]) /* IS_SCHEDULED */}
                {
                  this.renderInput({
                    ...this.state.inputItems[4],
                    isDisabled:
                      !this.state.inputs[identifiers.IS_SCHEDULED].value,
                  }) /* SCHEDULED_DATE */
                }
                {
                  this.renderInput({
                    ...this.state.inputItems[5],
                    isDisabled:
                      !this.state.inputs[identifiers.IS_SCHEDULED].value,
                  }) /* SCHEDULED_TIMEZONE */
                }
              </div>
            )}
            <Button
              text={strings.SAVE_DRAFT}
              handleClick={() => this.validate(false)}
              className="btn-primary btn-primary--margin-right"
              isPending={props.isUpdatePending}
            />
            <Button
              text={strings.SEND}
              handleClick={() => this.validate(true)}
              className="btn-secondary"
              isPending={props.isUpdatePending}
            />
          </div>
        </main>
      );
    }
    return <EmptyDetail history={props.history} />;
  }
}

NotificationDetail.propTypes = {
  notification: PropTypes.object,
};

NotificationDetail.defaultProps = {
  notification: {},
};

const mapStateToProps = (state) => ({
  notification: state.notifications.editNotification,
});

const mapDispatchToProps = {
  updateNotification,
  sendNotification,
  setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
