import React from 'react';
import PropTypes from 'prop-types';

class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      time: (props.value && props.value.time) || '',
      date: (props.value && props.value.date) || '',
    };
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.handleEnter();
    }
  };

  handleDateChange = (e) => {
    this.setState({
      date: e.target.value,
    });
    this.props.handleChange({ id: this.props.id, date: e.target.value, time: this.state.time });
  };

  handleTimeChange = (e) => {
    this.setState({
      time: e.target.value,
    });
    this.props.handleChange({ id: this.props.id, time: e.target.value, date: this.state.date });
  };

  render() {
    const { props } = this;
    return (
      <div className="form-group">
        <label htmlFor={props.id}>{props.label}</label>
        <div className="row">
          <div className="col">
            <input type={'date'} ref={r => this.date = r} className={`form-control ${!props.isValid ? 'is-invalid' : ''}`} id={props.id} placeholder={'DD-MM-YYYY'} value={this.state.date} onKeyPress={this.handleKeyPress} onChange={this.handleDateChange} disabled={props.isDisabled} />
            {!props.isValid && <div className="invalid-feedback">{props.errorMessage}</div>}
          </div>
          <div className="col">
            <input type={'time'} ref={r => this.time = r} className={`form-control ${!props.isValid ? 'is-invalid' : ''}`} id={`${props.id}-time`} placeholder={'hh:mm'} value={this.state.time} onKeyPress={this.handleKeyPress} onChange={this.handleTimeChange} disabled={props.isDisabled} />
          </div>
        </div>
      </div>
    );
  }
}


TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['date-time']),
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleEnter: PropTypes.func,
};

TextInput.defaultProps = {
  placeholder: '',
  type: 'text',
  isValid: true,
  errorMessage: '',
  isDisabled: false,
  value: {
    date: '',
    time: '',
  },
  handleEnter: () => { },
};

export default TextInput;
