/* eslint-disable import/no-anonymous-default-export */
export default {
  // GET MISSIONS
  GET_MISSIONS: 'GET_MISSIONS',
  GET_MISSIONS_PENDING: 'GET_MISSIONS_PENDING',
  GET_MISSIONS_FULFILLED: 'GET_MISSIONS_FULFILLED',
  GET_MISSIONS_REJECTED: 'GET_MISSIONS_REJECTED',

  // GET MISSION
  GET_MISSION_BY_ID: 'GET_MISSION_BY_ID',
  GET_MISSION_BY_ID_PENDING: 'GET_MISSION_BY_ID_PENDING',
  GET_MISSION_BY_ID_FULFILLED: 'GET_MISSION_BY_ID_FULFILLED',
  GET_MISSION_BY_ID_REJECTED: 'GET_MISSION_BY_ID_REJECTED',

  // CREATE MISSION
  CREATE_MISSION: 'CREATE_MISSION',
  CREATE_MISSION_PENDING: 'CREATE_MISSION_PENDING',
  CREATE_MISSION_FULFILLED: 'CREATE_MISSION_FULFILLED',
  CREATE_MISSION_REJECTED: 'CREATE_MISSION_REJECTED',

  // CREATE MISSION IMAGE
  CREATE_MISSION_IMAGE: 'CREATE_MISSION_IMAGE',
  CREATE_MISSION_IMAGE_PENDING: 'CREATE_MISSION_IMAGE_PENDING',
  CREATE_MISSION_IMAGE_FULFILLED: 'CREATE_MISSION_IMAGE_FULFILLED',
  CREATE_MISSION_IMAGE_REJECTED: 'CREATE_MISSION_IMAGE_REJECTED',

  // UPDATE MISSION
  UPDATE_MISSION: 'UPDATE_MISSION',
  UPDATE_MISSION_PENDING: 'UPDATE_MISSION_PENDING',
  UPDATE_MISSION_FULFILLED: 'UPDATE_MISSION_FULFILLED',
  UPDATE_MISSION_REJECTED: 'UPDATE_MISSION_REJECTED',

  // GET ACTIVE MISSION
  GET_ACTIVE_MISSION: 'GET_ACTIVE_MISSION',
  GET_ACTIVE_MISSION_PENDING: 'GET_ACTIVE_MISSION_PENDING',
  GET_ACTIVE_MISSION_FULFILLED: 'GET_ACTIVE_MISSION_FULFILLED',
  GET_ACTIVE_MISSION_REJECTED: 'GET_ACTIVE_MISSION_REJECTED',

  SET_MISSIONS_SORT: 'SET_MISSIONS_SORT',
};
