/* eslint-disable import/no-anonymous-default-export */
export default {
  // GET NOTIFICATIONS
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_PENDING: "GET_NOTIFICATIONS_PENDING",
  GET_NOTIFICATIONS_FULFILLED: "GET_NOTIFICATIONS_FULFILLED",
  GET_NOTIFICATIONS_REJECTED: "GET_NOTIFICATIONS_REJECTED",

  // CREATE NOTIFICATIONS
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CREATE_NOTIFICATION_PENDING: "CREATE_NOTIFICATION_PENDING",
  CREATE_NOTIFICATION_FULFILLED: "CREATE_NOTIFICATION_FULFILLED",
  CREATE_NOTIFICATION_REJECTED: "CREATE_NOTIFICATION_REJECTED",

  // REMOVE NOTIFICATIONS
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  REMOVE_NOTIFICATION_PENDING: "REMOVE_NOTIFICATION_PENDING",
  REMOVE_NOTIFICATION_FULFILLED: "REMOVE_NOTIFICATION_FULFILLED",
  REMOVE_NOTIFICATION_REJECTED: "REMOVE_NOTIFICATION_REJECTED",

  // UPDATE NOTIFICATIONS
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_PENDING: "UPDATE_NOTIFICATION_PENDING",
  UPDATE_NOTIFICATION_FULFILLED: "UPDATE_NOTIFICATION_FULFILLED",
  UPDATE_NOTIFICATION_REJECTED: "UPDATE_NOTIFICATION_REJECTED",

  // SEND NOTIFICATIONS
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  SEND_NOTIFICATION_PENDING: "SEND_NOTIFICATION_PENDING",
  SEND_NOTIFICATION_FULFILLED: "SEND_NOTIFICATION_FULFILLED",
  SEND_NOTIFICATION_REJECTED: "SEND_NOTIFICATION_REJECTED",

  SET_NOTIFICATIONS_SORT: "SET_NOTIFICATIONS_SORT",
  SET_EDIT_NOTIFICATION: "SET_EDIT_NOTIFICATION",
};
