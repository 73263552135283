import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment-timezone";
import { Overview } from "../../components";
import {
  createNotification,
  getNotifications,
  removeNotification,
  setSort,
  setEditNotification,
} from "../../redux/pushNotifications/actions";
import { getActiveMission } from "../../redux/missions/actions";
import { getStatusCodes } from "../../redux/codes/actions";
import constants from "../../redux/pushNotifications/constants";
import { setMessage } from "../../redux/messages/actions";
import { strings } from "../../utils";
import { identifiers } from "../../constants";

import sentIcon from "../../assets/images/sent.svg";
import draftIcon from "../../assets/images/draft.svg";
import scheduledIcon from "../../assets/images/scheduled.svg";

const statusIcons = {
  SENT: sentIcon,
  DRAFT: draftIcon,
  SCHEDULED: scheduledIcon,
};
const timezones = require("../../constants/timezones.json");

const NOTIFICATION_STATUS = {
  draft: "DRAFT",
  scheduled: "SCHEDULED",
  ready: "READY",
  sent: "SENT",
};

class NotificationsOverview extends PureComponent {
  componentDidMount() {
    this.props.getActiveMission();
  }

  createNotification = async (notification) => {
    if (!(this.props.activeMission && this.props.activeMission.id))
      return this.props.setMessage({
        type: identifiers.MESSAGE_ERROR,
        text: strings.NO_ACTIVE_MISSION,
      });

    let status = NOTIFICATION_STATUS.ready;
    if (notification.saveAsDraft) {
      status = NOTIFICATION_STATUS.draft;
    } else if (notification.isScheduled) {
      status = NOTIFICATION_STATUS.scheduled;
    }

    const formattedNotification = {
      title: notification.title,
      messageBody: notification.message,
      body: notification.longMessage,
      status,
      scheduled: notification.isScheduled
        ? this.createTimezonedTime(
            notification.scheduledDate,
            notification.scheduledTimeZone
          )
        : undefined,
      timezone: this.getTimeZone(notification),
    };
    const result = await this.props.createNotification(
      this.props.activeMission.id,
      formattedNotification
    );
    if (
      result.action &&
      result.action.type === constants.CREATE_NOTIFICATION_FULFILLED
    ) {
      this.props.setMessage({
        type: identifiers.MESSAGE_SUCCESS,
        text: strings.NEW_NOTIFICATION_CREATED,
      });
      return true;
    }
    return false;
  };

  createTimezonedTime = (dateTime, timezone) => {
    // Convert the date and timezone to the following format '2007-04-05T12:30-02:00';
    const { date, time } = dateTime || {};
    if (!date || !time || !timezone) return undefined;
    const tz = timezones.find((tz) => tz.key === timezone);
    const timezoned = moment.tz(`${date} ${time}`, tz.utc[0]);

    return timezoned.format();
  };

  getTimeZone = (notification) => {
    return timezones.find(
      (timezone) => timezone.key === notification.scheduledTimeZone
    ).utc[0];
  };

  getNoficiationsSorted = (page, limit, sortField, sortOrder, search = "") => {
    if (sortField && sortOrder) {
      this.props.setSort(sortField, sortOrder);
    }
    this.props.getNotifications(
      page,
      limit,
      sortField || this.props.sortField,
      sortOrder || this.props.sortOrder,
      search
    );
  };

  removeNotification = async (id) => {
    return this.props.removeNotification(id);
  };

  formatNotification = (notification) => {
    const createdAt = format(notification.createdAt, "DD/MM/YYYY HH:mm:ss");
    return {
      id: notification.id,
      createdAt,
      title: notification.title,
      longMessage: notification.body,
      message: notification.messageBody,
      isRemovable: notification.statusCode !== NOTIFICATION_STATUS.sent,
      statusCode: {
        type: "image",
        alt: notification.statusCode,
        src: statusIcons[notification.statusCode],
      },
    };
  };

  goToDetail = (id) => {
    const clickedNotification = this.props.notifications.find(
      (notification) => notification.id === id
    );
    if (clickedNotification.statusCode !== "SENT") {
      this.props.setEditNotification(clickedNotification);
      this.props.history.push(`${window.location.pathname}/${id}`, id);
    }
  };

  render() {
    return (
      <Overview
        title={strings.NOTIFICATIONS}
        keyword={strings.NOTIFICATION}
        keys={[
          {
            id: identifiers.TITLE,
            value: strings.TITLE,
            isSortable: true,
            sorter: identifiers.TITLE,
            width: 2,
          },
          {
            id: identifiers.MESSAGE,
            value: strings.MESSAGE,
            isSortable: false,
            width: 4,
          },
          {
            id: identifiers.LONG_MESSAGE,
            value: strings.LONG_MESSAGE,
            isSortable: false,
            width: 4,
          },
          {
            id: identifiers.CREATED_AT,
            value: strings.CREATED_AT,
            isSortable: true,
            sorter: identifiers.CREATED_AT,
            width: 2,
          },
          {
            id: identifiers.STATUS_CODE,
            value: strings.STATUS,
            isSortable: true,
            sorter: identifiers.STATUS,
            width: 1,
          },
        ]}
        listItems={this.props.notifications.map(this.formatNotification)}
        history={this.props.history}
        paginationTotalCount={this.props.notificationsCount}
        showNotificationsCreateModal={true}
        create={this.createNotification}
        isError={this.props.isError}
        isClickable={false}
        onClick={this.goToDetail}
        get={this.getNoficiationsSorted}
        errorMessage={this.props.errorMessage}
        deleteIdentifier={identifiers.TITLE}
        removeItem={this.removeNotification}
        isCreatePending={this.props.isCreatePending}
        isCreateError={this.props.isCreateError}
        sortField={this.props.sortField}
        sortOrder={this.props.sortOrder}
        setMessage={this.props.setMessage}
        email={this.props.email}
      />
    );
  }
}

NotificationsOverview.propTypes = {
  notification: PropTypes.object,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCreateError: PropTypes.bool.isRequired,
  isCreatePending: PropTypes.bool.isRequired,
  createNotification: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  activeMission: PropTypes.object.isRequired,
  getActiveMission: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.notificationsList,
  notificationsCount: state.notifications.notificationsCount,
  notification: state.notifications.notification,
  isCreatePending: state.notifications.isCreatePending,
  isCreateError: state.notifications.isCreateError,
  sortField: state.notifications.sortField,
  sortOrder: state.notifications.sortOrder,
  email: state.auth.email,
  statusCodes: state.codes.statusCodes,
  activeMission: state.missions.activeMission,
});

const mapDispatchToProps = {
  createNotification,
  getNotifications,
  removeNotification,
  setSort,
  setMessage,
  getStatusCodes,
  getActiveMission,
  setEditNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsOverview);
