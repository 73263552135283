import constants from "./constants";
import { notificationService } from "../../services";

export const createNotification = (missionId, notification) => {
  return {
    type: constants.CREATE_NOTIFICATION,
    payload: notificationService.create(missionId, notification),
  };
};

export const updateNotification = (id, notification) => {
  return {
    type: constants.UPDATE_NOTIFICATION,
    payload: notificationService.update(id, notification),
  };
};

export const sendNotification = (id) => {
  return {
    type: constants.SEND_NOTIFICATION,
    payload: notificationService.sendNotification(id),
  };
};

export const removeNotification = (id) => {
  return {
    type: constants.REMOVE_NOTIFICATION,
    payload: notificationService.removeNotification(id),
  };
};

export const getNotifications = (page, limit, sortField, sortOrder, search) => {
  return {
    type: constants.GET_NOTIFICATIONS,
    payload: notificationService.get(page, limit, sortField, sortOrder, search),
  };
};

export const setSort = (sortField, sortOrder) => {
  return {
    type: constants.SET_NOTIFICATIONS_SORT,
    payload: { sortField, sortOrder },
  };
};

export const setEditNotification = (notification) => {
  return {
    type: constants.SET_EDIT_NOTIFICATION,
    payload: notification,
  };
};
