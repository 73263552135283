/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_LANGUAGE_CODES: 'GET_LANGUAGE_CODES',
  GET_LANGUAGE_CODES_PENDING: 'GET_LANGUAGE_CODES_PENDING',
  GET_LANGUAGE_CODES_FULFILLED: 'GET_LANGUAGE_CODES_FULFILLED',
  GET_LANGUAGE_CODES_REJECTED: 'GET_LANGUAGE_CODES_REJECTED',

  GET_LANGUAGE_CODE_BY_ID: 'GET_LANGUAGE_CODE_BY_ID',
  GET_LANGUAGE_CODE_BY_ID_PENDING: 'GET_LANGUAGE_CODE_BY_ID_PENDING',
  GET_LANGUAGE_CODE_BY_ID_FULFILLED: 'GET_LANGUAGE_CODE_BY_ID_FULFILLED',
  GET_LANGUAGE_CODE_BY_ID_REJECTED: 'GET_LANGUAGE_CODE_BY_ID_REJECTED',

  CREATE_LANGUAGE_CODE: "CREATE_LANGUAGE_CODE",
  CREATE_LANGUAGE_CODE_PENDING: "CREATE_LANGUAGE_CODE_PENDING",
  CREATE_LANGUAGE_CODE_FULFILLED: "CREATE_LANGUAGE_CODE_FULFILLED",
  CREATE_LANGUAGE_CODE_REJECTED: "CREATE_LANGUAGE_CODE_REJECTED",

  DEPRECATE_LANGUAGE_CODE: "DEPRECATE_LANGUAGE_CODE",
  DEPRECATE_LANGUAGE_CODE_PENDING: "DEPRECATE_LANGUAGE_CODE_PENDING",
  DEPRECATE_LANGUAGE_CODE_FULFILLED: "DEPRECATE_LANGUAGE_CODE_FULFILLED",
  DEPRECATE_LANGUAGE_CODE_REJECTED: "DEPRECATE_LANGUAGE_CODE_REJECTED",

  UNDEPRECATE_LANGUAGE_CODE: "UNDEPRECATE_LANGUAGE_CODE",
  UNDEPRECATE_LANGUAGE_CODE_PENDING: "UNDEPRECATE_LANGUAGE_CODE_PENDING",
  UNDEPRECATE_LANGUAGE_CODE_FULFILLED: "UNDEPRECATE_LANGUAGE_CODE_FULFILLED",
  UNDEPRECATE_LANGUAGE_CODE_REJECTED: "UNDEPRECATE_LANGUAGE_CODE_REJECTED",

  GET_STATUS_CODES: 'GET_STATUS_CODES',
  GET_STATUS_CODES_PENDING: 'GET_STATUS_CODES_PENDING',
  GET_STATUS_CODES_REJECTED: 'GET_STATUS_CODES_REJECTED',
  GET_STATUS_CODES_FULFILLED: 'GET_STATUS_CODES_FULFILLED',

  SET_CODES_SORT: 'SET_CODES_SORT',
};
