import { Network } from "../utils";

export const get = async (page, limit, sortField, sortOrder, search = '') => {
  const pagination = `?offset=${page}&limit=${limit}`;
  const sort = (sortField && sortField !== '') ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
  const filter = (search !== '') ? `&search=${search}` : '';
  const result = await Network.get(`/missions${pagination}${sort}${filter}`);
  return result;
};

export const getMissionById = async id => {
  const result = await Network.get(`/missions/${id}`);
  return result;
};

export const getActiveMission = async () => {
  const result = await Network.get('/missions/active');
  return result;
};

export const create = async (mission) => {
  const result = await Network.post(`/missions`, mission);
  return result;
};

export const update = async (id, mission) => {
  const result = await Network.put(`/missions/${id}`, mission);
  return result;
};

export const createImage = async (id, file, type, timeOfDay = '') => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', file);
  if (timeOfDay) {
    formData.append('timeOfDay', timeOfDay);
  }

  const result = await Network.post(`/missions/${id}/images`, formData, headers);
  return result;
};
