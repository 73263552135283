
import strings from './localization';
import { regexes, validation } from '../constants';

export function validate(type, value) {
  switch (type) {
    case 'email':
      if (isEmpty(value)) {
        return {
          isValid: false,
          errorMessage: strings.LOGIN_EMAIL_REQUIRED,
        };
      } else if (isEmailRegex(value)) {
        return {
          isValid: false,
          errorMessage: strings.LOGIN_EMAIL_VALIDATION,
        };
      } else {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
    case 'password':
      if (isEmpty(value)) {
        return {
          isValid: false,
          errorMessage: strings.LOGIN_PASSWORD_REQUIRED,
        };
      } else if (value.length < validation.PASSWORD_LENGTH) {
        return {
          isValid: false,
          errorMessage: strings.PASSWORD_LENGTH,
        };
      } else {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
    case 'text':
      if (isEmpty(value)) {
        return {
          isValid: false,
          errorMessage: strings.FIELD_REQUIRED,
        };
      } else {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
    case 'date-time':
      if (typeof value !== 'object' || isEmpty(value.date) || isEmpty(value.time)) {
        return {
          isValid: false,
          errorMessage: strings.FIELD_REQUIRED,
        };
      } else if (!/^\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01]) (00|[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(`${value.date} ${value.time}`)) {
        return {
          isValid: true,
          errorMessage: strings.NO_VALID_DATE_TIME,
        };
      } else {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
    default:
      return {
        isValid: true,
        errorMessage: '',
      };
  }
}

function isEmpty(value) {
  return !value || value.trim() === '';
}

function isEmailRegex(value) {
  return !regexes.EMAIL.test(value);
}
