import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  BasicInput,
  Button,
  Checkbox,
  Modal,
  Dropdown,
  EmptyDetail,
  Spinner,
} from "../../components";
import { strings, validate } from "../../utils";
import { identifiers, currencies } from "../../constants";
import {
  getMissionById,
  updateMission,
  createMissionImage,
} from "../../redux/missions/actions";
import { setMessage } from "../../redux/messages/actions";
import "../../components/detail/detail.scss";

import arrowLeft from "../../assets/images/arrow-left.svg";
const IMAGE_TYPES = [
  {
    id: "image-flag",
    key: "flag",
    type: "FLAG",
    timeOfDay: null,
    label: strings.IMAGE_FLAG,
  },
  {
    id: "image-background-morning",
    key: "morning",
    type: "BACKGROUND",
    timeOfDay: "morning",
    label: strings.IMAGE_MORNING_BACKGROUND,
  },
  {
    id: "image-background-afternoon",
    key: "afternoon",
    type: "BACKGROUND",
    timeOfDay: "afternoon",
    label: strings.IMAGE_AFTERNOON_BACKGROUND,
  },
  {
    id: "image-background-evening",
    key: "evening",
    type: "BACKGROUND",
    timeOfDay: "evening",
    label: strings.IMAGE_EVENING_BACKGROUND,
  },
];

class MissionDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputs: !props.isPending ? this.setInputItems() : {},
      inputsLoaded: false,
      images: {},
      files: {},
      file: "",
    };
  }

  componentDidMount() {
    this.props.getMissionById(this.props.location.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.state !== this.props.location.state) {
      this.props.getMissionById(this.props.location.state);
    }
    if (!this.props.isPending && prevProps.isPending) {
      this.setState({
        inputs: this.setInputItems(),
        inputsLoaded: true,
        images: this.setImageItems(),
      });
    }
  }

  setImageItems = () => {
    const results = IMAGE_TYPES.reduce((images, item) => {
      images[item.key] =
        [...this.props.mission.images]
          .reverse()
          .find(
            (image) =>
              image.type === item.type && image.timeOfDay === item.timeOfDay,
          ) || {};
      return images;
    }, {});
    return results;
  };

  getInputItems = () => {
    if (this.props.isPending || !this.props.mission) return [];
    return [
      {
        id: identifiers.NAME,
        label: strings.NAME,
        type: "text",
        isEditable: true,
        value: this.props.mission.name,
      },
      {
        id: identifiers.START_DATE,
        label: strings.START_DATE,
        type: "date",
        isEditable: true,
        value: this.props.mission.startDate.split("T")[0],
      },
      {
        id: identifiers.END_DATE,
        label: strings.END_DATE,
        type: "date",
        isEditable: true,
        value: this.props.mission.endDate.split("T")[0],
      },
      {
        id: identifiers.CURRENCY_FROM,
        label: strings.CURRENCY_FROM,
        type: "select",
        options: currencies,
        isEditable: true,
        value: this.props.mission.currencyFrom,
      },
      {
        id: identifiers.CURRENCY_TO,
        label: strings.CURRENCY_TO,
        type: "select",
        options: currencies,
        isEditable: true,
        value: this.props.mission.currencyTo,
      },
      {
        id: identifiers.CURRENCY_FALLBACK_RATE,
        label: strings.CURRENCY_FALLBACK_RATE,
        tooltip: strings.CURRENCY_FALLBACK_RATE_TOOLTIP,
        type: "number",
        isEditable: true,
        value: this.props.mission.currencyFallbackRate,
      },
      {
        id: identifiers.IS_ACTIVE_MISSION,
        value: this.props.mission.isActiveMission,
        label: strings.IS_ACTIVE_MISSION,
        type: "boolean",
        isEditable: true,
      },
      {
        id: identifiers.RATING,
        label: strings.RATING,
        type: "boolean",
        isEditable: true,
        value: this.props.mission.showAppReviewPrompt,
      },
      {
        id: identifiers.BELFIUS_LINK,
        label: strings.BELFIUS_LINK,
        tooltip: strings.BELFIUS_LINK_TOOLTIP,
        type: "text",
        isEditable: true,
        value: this.props.mission.belfiusLink,
      },
    ];
  };

  setInputItems = () => {
    let inputs = {};
    this.getInputItems().forEach((item) => {
      if (item.type === "email") {
        inputs[item.id] = {
          value: item.value,
          validation: "email",
          isValid: true,
          errorMessage: "",
        };
      } else if (
        item.type === "text" ||
        item.type === "number" ||
        item.type === "date"
      ) {
        inputs[item.id] = {
          value: item.value,
          validation: "text",
          isValid: true,
          errorMessage: "",
        };
      } else if (item.type === "select" && item.options.length > 0) {
        inputs[item.id] = { value: item.value, validation: null };
      } else if (item.type === "boolean") {
        inputs[item.id] = { value: item.value, validation: null };
      }
    });
    return inputs;
  };

  handleChange = (event) => {
    const input = event.target.id;
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: {
          ...this.state.inputs[input],
          value:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          isValid: true,
        },
      },
    });
  };

  validate = () => {
    let inputs = { ...this.state.inputs };
    let hasError = false;
    Object.keys(inputs)
      .filter((key) => inputs.hasOwnProperty(key) && inputs[key].validation)
      .forEach((key) => {
        const validationResult = validate(
          inputs[key].validation,
          inputs[key].value,
        );
        if (!validationResult.isValid) {
          hasError = true;
        }
        inputs[key] = {
          ...inputs[key],
          ...validationResult,
        };
      });
    if (hasError) {
      this.setState({ inputs });
      return;
    }
    this.update(this.getInputValues(inputs));
  };

  resetChanges = () => {
    this.setState({ inputs: this.setInputItems() });
    this.props.setMessage({
      type: identifiers.MESSAGE_SUCCESS,
      text: strings.CHANGES_RESET,
    });
    return true;
  };

  handleImageChange = (key, type, timeOfDay) => (e) => {
    this.setState({
      files: {
        ...this.state.files,
        [key]: {
          file: e.target.files[0],
          type,
          timeOfDay,
        },
      },
    });
  };

  update = async (inputs) => {
    const imagesUpload = Promise.all(
      IMAGE_TYPES.map(async (item) => {
        const newImage = this.state.files[item.key];
        if (!newImage) return Promise.resolve();
        return this.props.createMissionImage(
          this.props.mission.id,
          newImage.file,
          newImage.type,
          newImage.timeOfDay,
        );
      }),
    );
    await imagesUpload;
    const result = await this.props.updateMission(
      this.props.mission.id,
      inputs,
    );
    if (result.action && result.action.type.includes("FULFILLED")) {
      this.props.setMessage({
        type: identifiers.MESSAGE_SUCCESS,
        text: strings.UPDATE_SUCCESS,
      });
    }
  };

  getInputValues(inputs) {
    for (let key in inputs) {
      inputs[key] = inputs[key].value;
    }
    return inputs;
  }

  renderInput = (item) => {
    if (item.type === "boolean") {
      return (
        <Checkbox
          css={item.css}
          key={item.id}
          id={item.id}
          text={item.label}
          value={
            (item.isEditable ? this.state.inputs[item.id].value : item.value) ||
            false
          }
          handleChange={this.handleChange}
          isDisabled={!item.isEditable || this.props.isUpdatePending}
        />
      );
    }
    if (item.type === "select") {
      return (
        <Dropdown
          key={item.id}
          id={item.id}
          label={item.label}
          value={
            this.state.inputs[item.id] ? this.state.inputs[item.id].value : ""
          }
          handleChange={this.handleChange}
          options={item.options}
        />
      );
    }
    if (item.type === "plain") {
      return (
        <div className={`form-group ${item.css}`} key={item.id}>
          <p>
            {item.label} {item.value}
          </p>
        </div>
      );
    }
    if (item.type === "password") {
      return (
        <BasicInput
          key={item.id}
          id={item.id}
          label={item.label}
          tooltip={item.tooltip}
          value={
            (item.isEditable ? this.state.inputs[item.id].value : item.value) ||
            ""
          }
          handleChange={this.handleChange}
          type={item.type}
          isDisabled={!item.isEditable || this.props.isUpdatePending}
        />
      );
    }
    return (
      <BasicInput
        key={item.id}
        id={item.id}
        label={item.label}
        tooltip={item.tooltip}
        value={
          (item.isEditable ? this.state.inputs[item.id].value : item.value) ||
          ""
        }
        handleChange={this.handleChange}
        type={item.type}
        isDisabled={!item.isEditable || this.props.isUpdatePending}
        isValid={item.isEditable ? this.state.inputs[item.id].isValid : true}
        errorMessage={
          item.isEditable ? this.state.inputs[item.id].errorMessage : ""
        }
      />
    );
  };

  renderImageUpload = (item) => {
    return (
      <div className="form-group" key={item.key}>
        <label htmlFor={item.id}>{item.label}</label>
        <p className="hint">
          {item.id === "image-flag"
            ? "(72 x 72, max 5MB)"
            : "(960 × 1704, max 5MB)"}
        </p>
        <input
          type={"file"}
          className={`form-control`}
          id={item.id}
          onChange={this.handleImageChange(item.key, item.type, item.timeOfDay)}
          disabled={this.props.isUpdatePending}
        />
        {this.state.images[item.key].fileUrl ? (
          <img
            src={this.state.images[item.key].fileUrl}
            alt={item.id}
            style={{ maxHeight: "100px" }}
          />
        ) : (
          strings.NO_IMAGE_YET
        )}
      </div>
    );
  };

  render() {
    const { state, props } = this;
    const overview = window.location.pathname.split("/")[1];

    if (props.isPending)
      return (
        <Spinner className="col-sm-9 offset-sm-3 col-md-10 offset-md-2 pt-3" />
      );
    if (props.mission) {
      return (
        <main className="detail col-sm-9 offset-sm-3 col-md-10 offset-md-2 pt-3">
          {state.inputsLoaded && (
            <div className="container">
              <div className="detail-header">
                <div
                  className="back text-primary"
                  onClick={props.history.goBack}
                >
                  <img src={arrowLeft} alt={strings.PREVIOUS} />
                  <span>{`${overview.charAt(0).toUpperCase()}${overview.slice(
                    1,
                  )}`}</span>
                </div>
              </div>
              <h3>{props.mission.name}</h3>
              <span className="text-primary">{`${strings.ID}: ${props.mission.id}`}</span>
              <div className="input-fields">
                {this.getInputItems().map((item) => this.renderInput(item))}
                <h5>{strings.MISSION_IMAGES}</h5>
                {IMAGE_TYPES.map(this.renderImageUpload)}
              </div>
              <div className={`detail-actions ${props.isMe ? "flex-end" : ""}`}>
                <div className="update-actions">
                  <Modal
                    id="reset-changes"
                    modalButtonText={strings.RESET_CHANGES}
                    handlePrimaryButton={this.resetChanges}
                    primaryButtonText={strings.RESET}
                    secondaryButtonText={strings.CANCEL}
                    modalButtonClassName="btn-light"
                    secondaryButtonClassName="btn-light"
                    primaryButtonClassName="btn-danger"
                  >
                    <p>{strings.RESET_CONFIRMATION}</p>
                  </Modal>
                  <Button
                    text={strings.SAVE}
                    handleClick={this.validate}
                    className="btn-primary"
                    isPending={props.isUpdatePending}
                  />
                </div>
              </div>
            </div>
          )}
        </main>
      );
    }
    return <EmptyDetail history={props.history} />;
  }
}

MissionDetail.propTypes = {
  mission: PropTypes.object,
  isPending: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getMissionById: PropTypes.func.isRequired,
  createMissionImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mission: state.missions.mission,
  isError: state.missions.isError,
  errorMessage: state.missions.errorMessage,
  isPending: state.missions.isPending,
  isCreatePending: state.missions.isCreatePending,
  isCreateError: state.missions.isCreateError,
});

const mapDispatchToProps = {
  getMissionById,
  setMessage,
  updateMission,
  createMissionImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionDetail);
