export const currencies = [
    { "key": "AED", "text": "AED" },
    { "key": "AFN", "text": "AFN" },
    { "key": "ALL", "text": "ALL" },
    { "key": "AMD", "text": "AMD" },
    { "key": "ANG", "text": "ANG" },
    { "key": "AOA", "text": "AOA" },
    { "key": "ARS", "text": "ARS" },
    { "key": "AUD", "text": "AUD" },
    { "key": "AWG", "text": "AWG" },
    { "key": "AZN", "text": "AZN" },
    { "key": "BAM", "text": "BAM" },
    { "key": "BBD", "text": "BBD" },
    { "key": "BDT", "text": "BDT" },
    { "key": "BGN", "text": "BGN" },
    { "key": "BHD", "text": "BHD" },
    { "key": "BIF", "text": "BIF" },
    { "key": "BMD", "text": "BMD" },
    { "key": "BND", "text": "BND" },
    { "key": "BOB", "text": "BOB" },
    { "key": "BRL", "text": "BRL" },
    { "key": "BSD", "text": "BSD" },
    { "key": "BTN", "text": "BTN" },
    { "key": "BWP", "text": "BWP" },
    { "key": "BYR", "text": "BYR" },
    { "key": "BZD", "text": "BZD" },
    { "key": "CAD", "text": "CAD" },
    { "key": "CDF", "text": "CDF" },
    { "key": "CHF", "text": "CHF" },
    { "key": "CLP", "text": "CLP" },
    { "key": "CNY", "text": "CNY" },
    { "key": "COP", "text": "COP" },
    { "key": "CRC", "text": "CRC" },
    { "key": "CUC", "text": "CUC" },
    { "key": "CVE", "text": "CVE" },
    { "key": "CZK", "text": "CZK" },
    { "key": "DJF", "text": "DJF" },
    { "key": "DKK", "text": "DKK" },
    { "key": "DOP", "text": "DOP" },
    { "key": "DZD", "text": "DZD" },
    { "key": "EEK", "text": "EEK" },
    { "key": "EGP", "text": "EGP" },
    { "key": "ERN", "text": "ERN" },
    { "key": "ETB", "text": "ETB" },
    { "key": "EUR", "text": "EUR" },
    { "key": "FJD", "text": "FJD" },
    { "key": "FKP", "text": "FKP" },
    { "key": "GBP", "text": "GBP" },
    { "key": "GEL", "text": "GEL" },
    { "key": "GHS", "text": "GHS" },
    { "key": "GIP", "text": "GIP" },
    { "key": "GMD", "text": "GMD" },
    { "key": "GNF", "text": "GNF" },
    { "key": "GQE", "text": "GQE" },
    { "key": "GTQ", "text": "GTQ" },
    { "key": "GYD", "text": "GYD" },
    { "key": "HKD", "text": "HKD" },
    { "key": "HNL", "text": "HNL" },
    { "key": "HRK", "text": "HRK" },
    { "key": "HTG", "text": "HTG" },
    { "key": "HUF", "text": "HUF" },
    { "key": "IDR", "text": "IDR" },
    { "key": "ILS", "text": "ILS" },
    { "key": "INR", "text": "INR" },
    { "key": "IQD", "text": "IQD" },
    { "key": "IRR", "text": "IRR" },
    { "key": "ISK", "text": "ISK" },
    { "key": "JMD", "text": "JMD" },
    { "key": "JOD", "text": "JOD" },
    { "key": "JPY", "text": "JPY" },
    { "key": "KES", "text": "KES" },
    { "key": "KGS", "text": "KGS" },
    { "key": "KHR", "text": "KHR" },
    { "key": "KMF", "text": "KMF" },
    { "key": "KPW", "text": "KPW" },
    { "key": "KRW", "text": "KRW" },
    { "key": "KWD", "text": "KWD" },
    { "key": "KYD", "text": "KYD" },
    { "key": "KZT", "text": "KZT" },
    { "key": "LAK", "text": "LAK" },
    { "key": "LBP", "text": "LBP" },
    { "key": "LKR", "text": "LKR" },
    { "key": "LRD", "text": "LRD" },
    { "key": "LSL", "text": "LSL" },
    { "key": "LTL", "text": "LTL" },
    { "key": "LVL", "text": "LVL" },
    { "key": "LYD", "text": "LYD" },
    { "key": "MAD", "text": "MAD" },
    { "key": "MDL", "text": "MDL" },
    { "key": "MGA", "text": "MGA" },
    { "key": "MKD", "text": "MKD" },
    { "key": "MMK", "text": "MMK" },
    { "key": "MNT", "text": "MNT" },
    { "key": "MOP", "text": "MOP" },
    { "key": "MRO", "text": "MRO" },
    { "key": "MUR", "text": "MUR" },
    { "key": "MVR", "text": "MVR" },
    { "key": "MWK", "text": "MWK" },
    { "key": "MXN", "text": "MXN" },
    { "key": "MYR", "text": "MYR" },
    { "key": "MZM", "text": "MZM" },
    { "key": "NAD", "text": "NAD" },
    { "key": "NGN", "text": "NGN" },
    { "key": "NIO", "text": "NIO" },
    { "key": "NOK", "text": "NOK" },
    { "key": "NPR", "text": "NPR" },
    { "key": "NZD", "text": "NZD" },
    { "key": "OMR", "text": "OMR" },
    { "key": "PAB", "text": "PAB" },
    { "key": "PEN", "text": "PEN" },
    { "key": "PGK", "text": "PGK" },
    { "key": "PHP", "text": "PHP" },
    { "key": "PKR", "text": "PKR" },
    { "key": "PLN", "text": "PLN" },
    { "key": "PYG", "text": "PYG" },
    { "key": "QAR", "text": "QAR" },
    { "key": "RON", "text": "RON" },
    { "key": "RSD", "text": "RSD" },
    { "key": "RUB", "text": "RUB" },
    { "key": "SAR", "text": "SAR" },
    { "key": "SBD", "text": "SBD" },
    { "key": "SCR", "text": "SCR" },
    { "key": "SDG", "text": "SDG" },
    { "key": "SEK", "text": "SEK" },
    { "key": "SGD", "text": "SGD" },
    { "key": "SHP", "text": "SHP" },
    { "key": "SLL", "text": "SLL" },
    { "key": "SOS", "text": "SOS" },
    { "key": "SRD", "text": "SRD" },
    { "key": "SYP", "text": "SYP" },
    { "key": "SZL", "text": "SZL" },
    { "key": "THB", "text": "THB" },
    { "key": "TJS", "text": "TJS" },
    { "key": "TMT", "text": "TMT" },
    { "key": "TND", "text": "TND" },
    { "key": "TRY", "text": "TRY" },
    { "key": "TTD", "text": "TTD" },
    { "key": "TWD", "text": "TWD" },
    { "key": "TZS", "text": "TZS" },
    { "key": "UAH", "text": "UAH" },
    { "key": "UGX", "text": "UGX" },
    { "key": "USD", "text": "USD" },
    { "key": "UYU", "text": "UYU" },
    { "key": "UZS", "text": "UZS" },
    { "key": "VEB", "text": "VEB" },
    { "key": "VND", "text": "VND" },
    { "key": "VUV", "text": "VUV" },
    { "key": "WST", "text": "WST" },
    { "key": "XAF", "text": "XAF" },
    { "key": "XCD", "text": "XCD" },
    { "key": "XDR", "text": "XDR" },
    { "key": "XOF", "text": "XOF" },
    { "key": "XPF", "text": "XPF" },
    { "key": "YER", "text": "YER" },
    { "key": "ZAR", "text": "ZAR" },
    { "key": "ZMK", "text": "ZMK" },
    { "key": "ZWR", "text": "ZWR" },
];